<script setup>
import config from "../config";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCookies } from "vue3-cookies";
import { mdiMenu, mdiHomeOutline, mdiCubeOutline, mdiAccountMultipleOutline, mdiHelpCircleOutline, mdiFormatListBulleted, mdiTranslate, mdiMenuUp, mdiMenuDown } from '@mdi/js'

const { locale } = useI18n();
const { cookies } = useCookies();
const active = ref(false);
const language = ref([
  {
    title: "English",
    locale: "en",
  },
  {
    title: "Русский",
    locale: "ru",
  },
]);
const setLocale = (lang) => {
  locale.value = lang;
  cookies.set("lang", lang);
  document.querySelector("html").setAttribute("lang", lang);
};
</script>

<template>
  <div class="wrap pp">
    <v-app-bar :height="60" elevation="0" class="app-bar">
      <v-app-bar-nav-icon :ripple="false">
        <img alt="Molepool" width="36" height="36" title="logo" src="../assets/logo.png" />
      </v-app-bar-nav-icon>
      <v-app-bar-title class="mx-auto title">
        {{ config.name }}
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-divider vertical inset></v-divider>
      <v-menu v-model="active">
        <template v-slot:activator="{ props }">
          <v-btn title="lang" class="mx-2" v-bind="props">
            <v-icon :icon="mdiTranslate"></v-icon>
            <v-icon :icon="active ? mdiMenuUp : mdiMenuDown"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in language" :key="index" @click="setLocale(item.locale)">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
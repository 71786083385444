<script setup>
import config from "../config";
</script>
<template>
  <div class="footer">
      <v-divider></v-divider>
      <v-footer color="#272727" elevation="0" class=" pa-0">
          <v-container class="wrap">
              <v-row align="center" justify="center" class="justify-space-between">
                  <v-col cols="auto" v-for="item in partners" :key="item">
                      <v-btn variant="text" target="_blank" :href="item.url">
                          <img :height="item.height" :src="`/partners/${item.name}.png`">
                      </v-btn>
                  </v-col>
              </v-row>
          </v-container>
      </v-footer>
      <v-divider></v-divider>
      <v-footer color="#272727" elevation="0" class="">
          <v-container class="wrap">
              <v-row>
                  <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4">
                      <div class="text-subtitle-1">
                          {{ $t('services') }}
                      </div>
                      <ul>
                          <li v-for="(item, i) in service" :key="i">
                              <a :href="item.url" target="_blank" rel="noopener noreferrer">
                                  {{ item.text }}
                              </a>
                          </li>
                      </ul>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4">
                      <div class="text-subtitle-1">
                          {{ $t('agreements') }}
                      </div>
                      <ul>
                          <li v-for="(item, i) in agreements" :key="i">
                              <a :href="item.url" target="_blank" rel="noopener noreferrer">
                                  {{ item.text }}
                              </a>
                          </li>
                      </ul>
                  </v-col>
                  <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                      <div class="text-subtitle-1">
                          {{ $t('feedback') }}
                      </div>
                      <ul>
                          <li v-for="(item, i) in feedback" :key="i">
                              <a :href="item.url" target="_blank" rel="noopener noreferrer">
                                  {{ item.text }}
                              </a>
                          </li>
                      </ul>
                  </v-col>
                  <!--v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                      <div class="text-subtitle-1">
                          {{ $t('compatible') }}
                      </div>
                      <ul>
                          <li>
                              <a href="https://www.miningrigrentals.com?ref=29888" target="_blank"
                                  rel="noopener noreferrer">
                                  <img class="mrr" width="120" src="@/assets/mrr.png" alt="">
                              </a>
                          </li>
                          <li>
                              <a href="https://www.nicehash.com/?refby=453cd539-1f58-49cf-8715-ed3ecf6b4c53"
                                  target="_blank" rel="noopener noreferrer">
                                  <img class="mrr" width="120" src="@/assets/nice.png" alt="">
                              </a>
                          </li>
                      </ul>
                  </v-col-->
              </v-row>
          </v-container>
      </v-footer>
      <v-divider></v-divider>
      <v-footer app elevation="0" class="app-footer">
          <v-row justify="center" no-gutters>
              <v-col class="text-center sub py-2" cols="12">
                  &copy; molepool.com 2017-{{ new Date().getFullYear() }}
              </v-col>
          </v-row>
      </v-footer>
  </div>
</template>

<script>
export default {
  name: "FooterItem",
  data() {
      return {
          partners: [
              {
                  name: 'blockchair',
                  url: 'https://blockchair.com',
                  height: 26,
              },
              {
                  name: 'coingecko',
                  url: 'https://www.coingecko.com',
                  height: 28,
              },
              {
                  name: 'miningpoolstats',
                  url: 'https://miningpoolstats.stream/molepool.com_pools',
                  height: 24,
              },
              {
                  name: 'mrr',
                  url: 'https://www.miningrigrentals.com/register?ref=29888',
                  height: 24,
              },
          ],
          service: [
              {
                  text: 'Mining Calculator',
                  url: 'https://whattomine.com',
              },
              //{
              //    text: 'https://ultramining.com/',
              //    url: 'https://www.nicehash.com/?refby=453cd539-1f58-49cf-8715-ed3ecf6b4c53',
              //},
              {
                  text: 'Mining Rig Rentals',
                  url: 'https://www.miningrigrentals.com/register?ref=29888',
              },
          ],
          agreements: [
              {
                  text: 'Privacy Statement',
                  url: '/terms/privacy_statement.pdf'
              },
              {
                  text: 'Terms and Conditions',
                  url: '/terms/terms_and_conditions.pdf'
              }
          ],
          feedback: [
              {
                  text: 'Telegram',
                  url: 'https://t.me/molepoolnet',
              },
              {
                  text: 'Telegram News',
                  url: 'https://t.me/Molepool_news',
              },
              {
                  text: 'Email',
                  url: 'mailto:info.molepool@gmail.com',
              },
          ]
      };
  },

}
</script>
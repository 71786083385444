import { createStore } from "vuex";
import axios from "axios";


export default createStore({
    state: {
        status: "pending",
        error: "",
        home: [],
    },
    getters: {
        getStatus: (state) => state.status,
        getHome: (state) => state.home,
    },
    actions: {
        async fetchHome({ commit }) {
            try {
                const data = await axios.get(`/api/v1/index`);
                commit("SET_HOME", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_HOME(state, home) {
            state.home = home;
        },
    },
});

<script setup>
import config from "../config";
import { onMounted, onUnmounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { mdiIncognito, mdiSourceRepositoryMultiple, mdiCodeTagsCheck } from '@mdi/js'


const store = useStore();


const status = computed({
  get() {
    return store.state.status;
  },
  set(value) {
    store.commit("SET_STATUS", value);
  },
});

const stats = computed({
  get() {
    return store.state.home;
  },
  set(value) {
    store.commit("SET_HOME", value);
  },
});

let intervalId;
onMounted(() => {
  store.dispatch("fetchHome");
  intervalId = setInterval(() => {
    store.dispatch("fetchHome");
  }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>
<template>
  <div class="d-flex justify-space-between card mb-2">
    <h3 class="mt-1">
      {{ $t('mining', { msg: "SOLO" }) }}
    </h3>
  </div>

  <v-table class="border rounded mb-4">
    <thead>
      <tr>
        <th class="text-left">{{ $t('pool') }}</th>
        <th class="text-left">{{ $t('algorithm') }}</th>
        <th class="text-left">{{ $t('miners') }}</th>
        <th class="text-left">{{ $t('hashrate') }}</th>
        <th class="text-left">{{ $t('network_hashrate') }}</th>
        <th class="text-left">{{ $t('network_difficulty') }}</th>
        <th class="text-left">{{ $t('price') }}</th>
        <th class="text-left">{{ $t('reward') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in stats" :key="index">
        <td>
          <v-btn variant="plain" :ripple="false" size="" class="text-capitalize font-weight-regular"
            style="opacity: 1; widows: auto; letter-spacing: 0; font-size: 1.1rem;"
            :href="`https://${item.coin.toLowerCase()}.molepool.com`" target="_blank">
            <template v-slot:prepend>
              <v-icon start class="mr-4">
                <img alt="Molepool" width="36" height="36" title="logo" :src="`/coin/${item.coin.toLowerCase()}.png`" />
              </v-icon>
            </template>
            {{ item.coin == 'LTC' ? item.name : item.coin }}
          </v-btn>
        </td>
        <td>
          {{ item.algo }}
        </td>
        <td>
          {{ item.totalMiners }}
        </td>
        <td>
          <span v-if="item.algo == 'Equihash'">
            <span v-html="$filters.hashrateSol(item.totalHashrate, 2)"></span>
          </span>
          <span v-else>
            <span v-html="$filters.hashrate(item.totalHashrate, 2)"></span>
          </span>
        </td>
        <td>
          <span v-if="item.algo == 'Equihash'">
            <span v-html="$filters.hashrateSol(item.networkHashrate, 2)"></span>
          </span>
          <span v-else>
            <span v-html="$filters.hashrate(item.networkHashrate, 2)"></span>
          </span>
        </td>
        <td>
          <span v-html="$filters.difficulty(item.networkDifficulty, 2)"></span>
        </td>
        <td>
          {{ $filters.price(item.usd, 3) }}
          <span v-if="String(item.usd_24h_change)[0] == '-'" class="text-red sub">
            {{ $filters.price_percent(item.usd_24h_change) }}
            <v-icon right size="14" class="mx-0 text-red">mdi-arrow-down</v-icon>
          </span>
          <span v-else class="text-green sub">
            +{{ $filters.price_percent(item.usd_24h_change) }}
            <v-icon right size="14" class="mx-0 text-green">mdi-arrow-up</v-icon>
          </span>
        </td>
        <td>
          {{ $filters.reward(item.reward, 3) }} <small>{{ item.coin }}</small>
          (<span class="text-green sub">{{ $filters.price(item.reward * item.usd) }}</span>)
        </td>
      </tr>
    </tbody>
  </v-table>

  <v-item-group class="mb-2">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-list lines="two" class="card border rounded py-2">
          <v-list-item :title="$t('name_0')" :subtitle="$t('text_0')" :prepend-icon="mdiIncognito"></v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-list lines="two" class="card border rounded py-2">
          <v-list-item :title="$t('name_2')" :subtitle="$t('text_2')"
            :prepend-icon="mdiSourceRepositoryMultiple"></v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-list lines="two" class="card border rounded py-2">
          <v-list-item :title="$t('name_3')" :subtitle="$t('text_3')" :prepend-icon="mdiCodeTagsCheck"></v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-item-group>
</template>